<template>
  <div class="wap-credit">
    <headerBar :title="$t('dai-kuan-shen-qing')" @back="back"></headerBar>

    <div class="top flex-column-center">
      <div class="title">
        {{ $t('chuang-ye-dai-kuan-jie-jue-jie-qian-de-fan-nao') }}
      </div>
      <div class="sub-title">
        {{ $t('ti-gong-chuang-ye-dai-kuan-zi-jin-zhou-zhuan-fu-wu') }}
      </div>

      <div class="btn-box">
        <Button @click="toPath('wapLoanApply')">{{
          $t('zai-xian-shen-qing')
        }}</Button>
        <Button class="plain" @click="toPath('wapMyLoan')">{{
          $t('wo-de-dai-kuan')
        }}</Button>
      </div>
    </div>
    <div class="page-title">{{ $t('dai-kuan-wu-you') }}</div>

    <div class="desc-list flex-column-center">
      <div class="desc" v-for="(item, i) in descList" :key="i">
        <i :class="item.icon"></i>
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="label">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <div class="center-banner">
      <div class="about">
        <div class="page-title">{{ $t('guan-yu-wo-men') }}</div>
        <div class="info">
          {{
            $t(
              'wo-men-zhi-li-yu-dai-kuan-jin-rong-fu-wu-ji-yu-ke-hu-zhi-shang-de-jing-ying-li-nian-yi-jie-jue-ke-hu-zi-jin-zhou-zhuan-wen-ti-wei-shi-ming-gong-si-cong-cheng-li-zhi-jin-yi-bang-zhu-wu-shu-liu-ke-hu-jie-jue-zi-jin-zhou-zhuan-wen-ti'
            )
          }}
        </div>
        <div class="info">
          {{
            $t(
              'e-du-100000-zhi-30000000-li-xi-di-huan-kuan-fang-shi-ling-huo-wu-ren-he-qian-qi-fei-yong-xian-shang-fang-kuan-bu-da-shen-he-dian-hua-xin-xi-bao-mi-dang-tian-fang-kuan-1'
            )
          }}
        </div>
        <div class="info desc">
          {{
            $t(
              'bu-xiang-xiang-tong-xue-peng-you-kai-kou-jie-qian-qing-zhao-wo-men'
            )
          }}
        </div>
        <div class="btn">
          <div class="btn1 flex-center">
            {{ $t('zhi-li-yu-chuang-ye-zhe-dai-kuan-fu-wu') }}
          </div>
          <div class="btn2 flex-center">
            {{ $t('jie-jue-nin-de-zi-jin-kun-rao') }}
          </div>
        </div>
      </div>
    </div>

    <div class="page-title">
      {{ $t('he-zuo-huo-ban-zai-xian-jie-dai-ping-tai') }}
    </div>
    <div class="company-list flex-wrap">
      <div class="list" v-for="(item, i) in companyList" :key="i">
        <img :src="item.icon" alt="" />
      </div>
    </div>

    <footerBar></footerBar>
  </div>
</template>
<script>
import { Button, DatetimePicker, Popup, Switch as vanSwitch, Field } from 'vant'
import { shopProductList } from '@/api/shop'
import headerBar from '@/components/header'
import footerBar from '@/components/footer/wapFooter'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    vanSwitch,
    Popup,
    Field,
    footerBar
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      descList: [
        {
          icon: 'icon icon-meiyuan9',
          name: this.$t('ling-huoedu'),
          desc: this.$t(
            '100000-zhi-30000000edu-ling-huo-mei-you-xian-zhi-zui-chang-dai-kuan-qi-xian-yi-nian'
          )
        },
        {
          icon: 'icon icon-huojianjiasu',
          name: this.$t('ji-su-fang-kuan'),
          desc: this.$t(
            'zui-kuai-yi-xiao-shi-tong-guo-shen-he-shen-he-tong-guo-li-ji-xia-kuan'
          )
        },
        {
          icon: 'icon icon-dunpai',
          name: this.$t('an-quan-wu-you-0'),
          desc: this.$t(
            'wu-di-ya-dai-kuan-wu-xu-ya-jin-bu-da-hui-fang-dian-hua-zhe-ben-jiu-shi-yi-jian-si-mi-de-shi'
          )
        }
      ],
      companyList: [
        {
          icon: require('@/assets/imgs/company1.png')
        },
        {
          icon: require('@/assets/imgs/company2.png')
        },
        {
          icon: require('@/assets/imgs/company3.png')
        },
        {
          icon: require('@/assets/imgs/company4.png')
        },
        {
          icon: require('@/assets/imgs/company5.png')
        },
        {
          icon: require('@/assets/imgs/company6.png')
        },
        {
          icon: require('@/assets/imgs/company7.png')
        },
        {
          icon: require('@/assets/imgs/company8.png')
        },
        {
          icon: require('@/assets/imgs/company9.png')
        },
        {
          icon: require('@/assets/imgs/company10.png')
        },
        {
          icon: require('@/assets/imgs/company11.png')
        },
        {
          icon: require('@/assets/imgs/company12.png')
        },
        {
          icon: require('@/assets/imgs/company13.png')
        }
      ]
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>